import React from "react";
import CuadroListaEditable from "../edicion/CuadroListaEditable.js";
import * as Data from "../edicion/data/DataPersonaArbitro.js";
import * as DTOs from "../edicion/DTOs/PersonaArbitroDTOs.js";
import * as Funciones from "../edicion/funciones/FuncionesPersonaArbitro.js";
import styles from "./DatosPersona.module.css";

const CuadroListaArbitro = (props) => {
  const idPersona = props.persona && props.persona.id;

  return (
    <div className={`${styles.tablaArbitro}`}>
        <CuadroListaEditable
        titulo="Arbitro"
        idPadre={idPersona}
        data={Data}
        dtos={DTOs}
        funciones={Funciones}
        />
    </div>
  );
};

export default CuadroListaArbitro;