import React from "react";
import CampoEditableFechaCampos from "../../../UI/campos/CampoEditableFechaCampos.js";
import CampoEditableMultilinea from "../../../UI/campos/CampoEditableMultilinea.js";
import CampoEditableNumero from "../../../UI/campos/CampoEditableNumero.js";
import CampoEditableTexto from "../../../UI/campos/CampoEditableTexto.js";
import * as TipoEdicion from "../../../constantes/TipoEdicion.js";
import styles from "../Parametros.module.css";

const campos = {
  ID: "id",
  DESCRIPCION: "descripcion",
  NOMBRE: "nombre",
  FECHA_FUNDACION: "fecFundacion",
  CONTINENTE: "nombreContinente",
  OBSERVACIONES: "observaciones",
};

const CamposConfederacion = (props) => {
  const parametro = props.parametro;
  const tipoEdicion = props.tipoEdicion;

  const onCampoChange = (campo, valor) => {
    switch (campo) {
      case campos.ID:
        props.onCampoChange({ ...parametro, id: valor });
        break;
      case campos.DESCRIPCION:
        props.onCampoChange({ ...parametro, descripcion: valor });
        break;
      case campos.NOMBRE:
        props.onCampoChange({ ...parametro, nombre: valor });
        break;
      case campos.FECHA_FUNDACION:
        props.onCampoChange({
          ...parametro,
          anioFundacion: valor[0],
          mesFundacion: valor[1],
          diaFundacion: valor[2],
        });
        break;
      case campos.CONTINENTE:
        props.onCampoChange({ ...parametro, continente: valor });
        break;
      case campos.OBSERVACIONES:
        props.onCampoChange({ ...parametro, observaciones: valor });
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <table className={`${styles.tablaDatos}`}>
        <tbody>
          <tr>
            <td className={`${styles.titulo}`}>Id</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={campos.ID}
                valor={parametro && parametro.id}
                editar={tipoEdicion === TipoEdicion.ALTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Descripción</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.DESCRIPCION}
                valor={parametro && parametro.descripcion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Nombre</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.NOMBRE}
                valor={parametro && parametro.nombre}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Fecha fundación</td>
            <td className={`${styles.valor}`}>
              <CampoEditableFechaCampos
                nombre={campos.FECHA_FUNDACION}
                anio={parametro && parametro.anioFundacion}
                mes={parametro && parametro.mesFundacion}
                dia={parametro && parametro.diaFundacion}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                actualizar={false}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Continente</td>
            <td className={`${styles.valor}`}>
              <CampoEditableTexto
                nombre={campos.CONTINENTE}
                valor={parametro && parametro.nombreContinente}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                nombre={campos.OBSERVACIONES}
                valor={parametro && parametro.observaciones}
                filas={3}
                columnas={40}
                editar={tipoEdicion !== TipoEdicion.CONSULTA}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CamposConfederacion;
