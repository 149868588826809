export const ItemVacio = (idPadre) => {
    return {
      id: null,
      idPersona: idPadre,
      paisArbitro: null,
      anioDebut: null,
      anioInternacional: null,
      internacional: false,
      anioRetiro: null,
      retirado: false,
      observaciones: "",
      dudas: "",
    }
  };

export const saveItem = (item) => {
  return {
    id: item.id,
    idPersona: item.idPersona,
    idPais: item.paisArbitro && item.paisArbitro.id,
    anioDebut: item.anioDebut,
    anioInternacional: item.anioInternacional,
    internacional: item.internacional,
    anioRetiro: item.anioRetiro,
    retirado: item.retirado,
    observaciones: item.observaciones,
    dudas: item.dudas,
  };
};
