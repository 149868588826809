import React from "react";
import CampoComboPais from "../../UI/campos/CampoComboPais.js";
import CampoEditableCheck from "../../UI/campos/CampoEditableCheck.js";
import CampoEditableNumero from "../../UI/campos/CampoEditableNumero.js";
import * as Campos from "../../constantes/Campos.js";
import * as LargoCampos from "../../largoCampos/LargoCampos.js";
import styles from "../EditarItemLista.module.css";
import CampoEditableMultilinea from "../../UI/campos/CampoEditableMultilinea.js";

const CamposPersonaArbitro = (props) => {
  const item = props.item;
  const editar = props.editar;
  //const actualizar = props.actualizar;

  const onCampoChange = (campo, valor) => {
    props.onCampoChange(campo, valor);
  };

  return (
    <React.Fragment>
      {item && (
        <React.Fragment>
          <tr>
            <td className={`${styles.titulo}`}>Pais</td>
            <td className={`${styles.valor}`}>
              <CampoComboPais 
                nombre={Campos.Arbitro.PAIS}
                valor={item.paisArbitro}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Año debut</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.Arbitro.ANIO_DEBUT}
                valor={item.anioDebut}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Internacional</td>
            <td className={`${styles.valor}`}>
              <CampoEditableCheck
                nombre={Campos.Arbitro.INTERNACIONAL}
                checked={item.internacional}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Año</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.Arbitro.ANIO_INTERNACIONAL}
                valor={item.anioInternacional}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Retirado</td>
            <td className={`${styles.valor}`}>
              <CampoEditableCheck
                nombre={Campos.Arbitro.VERIFICADO}
                checked={item.retirado}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Año</td>
            <td className={`${styles.valor}`}>
              <CampoEditableNumero
                nombre={Campos.Arbitro.ANIO_INTERNACIONAL}
                valor={item.anioRetiro}
                largo={LargoCampos.ANIO}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.titulo}`}>Observaciones</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="3"
                columnas="46"
                nombre={Campos.Arbitro.OBSERVACIONES}
                valor={item && item.observaciones}
                largo={LargoCampos.OBSERVACIONES}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
          <tr>
            <td className={`${styles.tituloLow}`}>Dudas</td>
            <td className={`${styles.valor}`}>
              <CampoEditableMultilinea
                filas="3"
                columnas="46"
                nombre={Campos.Arbitro.DUDAS}
                valor={item && item.dudas}
                largo={LargoCampos.DUDAS}
                editar={editar}
                onChange={onCampoChange}
              />
            </td>
          </tr>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CamposPersonaArbitro;
