export const EsMismoNumero = (a, b) => {
    return Number(a) === Number(b);
};

export const EsMayor = (a, b) => {
    return Number(a) > Number(b);
};

export const EsMayorIgual = (a, b) => {
    return Number(a) >= Number(b);
};
