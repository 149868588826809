import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import CuadroEditableClub from "./CuadroEditableClub.js";
import EquiposClub from "./EquiposClub.js";
import EstadiosClub from "./EstadiosClub.js";
import NombresClub from "./NombresClub.js";
import PartidosClub from "./PartidosClub.js";
import TorneosClub from "./TorneosClub.js";
import FichaSolapas from "../UI/FichaSolapas.js";
import { Cuadro, NombreClub } from "../funciones/FuncionesClub.js";
import useGetURL from "../../hooks/useGetURL.js";
import styles from "../UI/FichaSolapas.module.css";

const Solapas = {
  DATOS: "datos",
  TORNEOS: "torneos",
  PARTIDOS: "partidos",
  JUGADORES: "jugadores",
};

const listaSolapas = [
  { nombre: Solapas.DATOS, visible: true },
  { nombre: Solapas.TORNEOS, visible: true },
  { nombre: Solapas.PARTIDOS, visible: true },
  { nombre: Solapas.JUGADORES, visible: true },
];

const DatosClub = () => {
  const { id: idClub } = useParams();
  const { state } = useLocation();
  const [club, setClub] = useState(null);
  const { isCargando, erroresGet, fetchData, resetErroresGet } = useGetURL();
  const esNuevo = state && state.nuevo;

  useEffect(() => {
    resetErroresGet();
  }, [resetErroresGet]);

  useEffect(() => {
    if (idClub) {
      fetchData("clubes/" + idClub, setClub);
    } else {
      setClub(null);
    }
  }, [fetchData, idClub]);

  const mostrarDatosSolapa = (solapa) => {
    switch (solapa) {
      case Solapas.DATOS:
        return (
          <div className={`${styles.columnas}`}>
            <div className={`${styles.columnaDoble}`}>
              <CuadroEditableClub cuadro={Cuadro.DATOS_BASICOS} club={club} nuevo={esNuevo} />
              <CuadroEditableClub cuadro={Cuadro.FECHAS} club={club} nuevo={esNuevo} />
              <NombresClub club={club} />
            </div>
            <div className={`${styles.columnaDoble}`}>
              <EstadiosClub club={club} />
              <EquiposClub club={club} />
            </div>
          </div>
        );
      case Solapas.TORNEOS:
        return (
          <div className={`${styles.columnaSimple}`}>
            <TorneosClub club={club} />
          </div>)
      case Solapas.PARTIDOS:
        return (
          <div className={`${styles.columnaSimple}`}>
            <PartidosClub club={club} />
          </div>)
      default:
        return null;
    }
  };

  return (
    <FichaSolapas
      item={club}
      titulo={NombreClub(club)}
      solapas={listaSolapas}
      solapaInicial={Solapas.DATOS}
      isCargando={isCargando}
      errores={erroresGet}
      mostrarDatosSolapa={mostrarDatosSolapa}
    />
  );
};

export default DatosClub;
