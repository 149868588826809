import React, { useCallback, useEffect, useReducer } from "react";
import DatePicker from "react-datepicker";
import CampoEditableNumero from "./CampoEditableNumero.js";
import SelectMes from "../../selectores/SelectMes.js";
import * as FuncionesFecha from "../../funciones/FuncionesFecha.js";
import LargoCampos from "../../largoCampos/LargoCampos.js";
import styles from "./CampoEditableFechaCampos.module.css";

const Accion = {
  ANIO: "anio",
  MES: "mes",
  DIA: "dia",
  FECHA_TEXTO: "textoFecha",
  FECHA_VALIDA: "fechaValida",
  PARCIAL: "parcial",
  ACTUALIZAR: "actualizar",
};

const FechaBase = (anioActual, mesActual, diaActual) => {
  const mesReal = mesActual ? mesActual - 1 : null;
  return {
    anio: anioActual,
    mes: mesReal,
    dia: diaActual,
    fechaTexto: FuncionesFecha.CamposToFechaCorta(anioActual, mesReal, diaActual),
    parcial: anioActual ? (!diaActual || !mesActual) : false,
  };
};

const ReducerFecha = (state, action) => {
  switch (action.type) {
    case Accion.ANIO:
      return {
        ...state,
        fechaTexto: FuncionesFecha.CamposToFechaCorta(action.value, state.mes, state.dia),
        anio: action.value,
      };
    case Accion.MES:
      return {
        ...state,
        fechaTexto: FuncionesFecha.CamposToFechaCorta(state.anio, action.value, state.dia),
        mes: action.value,
      };
    case Accion.DIA:
      return {
        ...state,
        fechaTexto: FuncionesFecha.CamposToFechaCorta(state.anio, state.mes, action.value),
        dia: action.value,
      };
    case Accion.FECHA_VALIDA:
      const campos = FuncionesFecha.FechaCortaToCampos(action.value);
      return {
        ...state,
        fechaTexto: action.value,
        anio: campos[0],
        mes: campos[1],
        dia: campos[2],
      };
    case Accion.PARCIAL:
      let fecha = "";
      if (action.value === true) {
        fecha = FuncionesFecha.CamposToFechaCorta(state.anio, state.mes, null);
      } else {
        fecha = FuncionesFecha.CamposToFechaCorta(state.anio, state.mes, state.dia ? state.dia : 1);
      }
      return {
        ...state,
        fechaTexto: fecha,
        parcial: action.value,
      };
    case Accion.ACTUALIZAR:
      return action.value;
    default:
      return FechaBase(null, null, null);
  }
};

const CampoEditableFechaCampos = (props) => {
  const nombre = props.nombre;
  const anio = props.anio;
  const mes = props.mes ? props.mes + 1 : null;
  const dia = props.dia;
  const editar = props.editar;
  const actualizar = props.actualizar;
  const [fecha, setFecha] = useReducer(ReducerFecha, FechaBase(anio, mes, dia));

  const actualizarReducer = useCallback(() => {
    setFecha({type: Accion.ACTUALIZAR, value: FechaBase(anio, mes, dia)});
  }, [anio, mes, dia])

  useEffect(() => {
    actualizarReducer();
  }, [actualizar, actualizarReducer])

  const onChangeFechaHandler = (fecha) => {
    if (fecha) {
      setFecha({type: Accion.FECHA_VALIDA, value: FuncionesFecha.FechaCorta(fecha)});
      props.onChange(nombre, FuncionesFecha.DateToArray(fecha));
    }
  };

  const onChangeParcialHandler = () => {
    setFecha({type: Accion.PARCIAL, value: !fecha.parcial});
    if (fecha.anio) {
      props.onChange(nombre, [fecha.anio, fecha.mes, null]);
    } else {
      props.onChange(nombre, [null, null, null]);
    }
  };

  const onChangeAnio = (nombreCampo, anioElegido) => {
    setFecha({type: Accion.ANIO, value: anioElegido});
    if (anioElegido) {
      props.onChange(nombre, [anioElegido, fecha.mes, null]);
    } else {
      props.onChange(nombre, [null, null, null]);
    }
  };

  const onMesElegido = (mesElegido) => {
    const valor = mesElegido ? Number(mesElegido) : null;
    setFecha({type: Accion.MES, value: valor});
    if (fecha.anio) {
      props.onChange(nombre, [fecha.anio, valor, null]);
    } else {
      props.onChange(nombre, [null, null, null]);
    }
  };

  let camposFecha = "";
  if (editar) {
    if (fecha.parcial) {
      camposFecha = (
        <React.Fragment>
          <SelectMes
            className={`${styles.campoMes}`}
            mesActual={fecha.mes}
            onMesElegido={onMesElegido}
          />
          <CampoEditableNumero
            estilo={`${styles.campoAnio}`}
            nombre={nombre}
            valor={fecha.anio}
            largo={LargoCampos.ANIO}
            editar={true}
            onChange={onChangeAnio}
          />
        </React.Fragment>
      );
    } else {
      camposFecha = (
        <DatePicker
          className={`${styles.campoFecha}`}
          key={nombre}
          selected={FuncionesFecha.FechaCortaToDate(fecha.fechaTexto)}
          dateFormat="dd/MM/yyyy"
          utcOffset={0}
          onChange={onChangeFechaHandler}
        />
/*        <input
          className={`${styles.campoFecha}`}
          key={nombre}
          value={fecha.fechaTexto}
          onChange={onChangeFechaHandler}
        />*/
      );
    }
  } else {
    camposFecha = fecha.fechaTexto;
  }

  return (
    <div className={`${styles.filaDatos}`}>
      {camposFecha}
      {editar && (
        <React.Fragment>
          <input
            type="checkbox"
            checked={fecha.parcial}
            onChange={onChangeParcialHandler}
          />
          <label className={`${styles.textoParcial}`}>Parcial</label>
        </React.Fragment>
        )}
    </div>
  );
};

export default CampoEditableFechaCampos;
