import { Method } from "./DataUtils.js";
import * as DataUtils from "./DataUtils.js";
import * as DTOs from "../DTOs/PersonaDTOs.js";

export const GetJugador = async (idPersona) => {
  return DataUtils.FetchObject("jugadores/" + idPersona);
};

export const GetTecnico = async (idPersona) => {
  return DataUtils.FetchObject("tecnicos/" + idPersona);
};

export const GetRegistrosArbitro = async (idPersona) => {
  return DataUtils.FetchObject("personas/" + idPersona + "/arbitro");
};

export const GetPersona = async (idPersona) => {
  const persona = await Promise.resolve(DataUtils.FetchObject("personas/" + idPersona));
  let jugador = null;
  let tecnico = null;
  let arbitro = null;
  if (persona.esJugador) {
    jugador = await GetJugador(idPersona);
  }
  if (persona.esTecnico) {
    tecnico = await GetTecnico(idPersona);
  }
  if (persona.esArbitro) {
    arbitro = await GetRegistrosArbitro(idPersona);
  }
  return {
    persona: persona,
    jugador: jugador,
    tecnico: tecnico,
    arbitro: arbitro,
  }
};

export const PostNuevaPersona = async (filtros) => {
  const idNuevo = await DataUtils.SaveObject(
    Method.POST,
    "personas/",
    DTOs.saveNuevaPersona(filtros)
  );
  return idNuevo;
};

export const SearchPersonasNueva = async (filtros) => {
  const clubes = await DataUtils.SearchLista(
    "personas/filtros_crear",
    DTOs.searchNuevaPersona(filtros)
  )
  return clubes;
}
