import React from "react";
import ColumnaBotoneraItem from "../ColumnaBotoneraItem.js";
import { TextoAnioConBoolean } from "../../funciones/FuncionesFecha.js";
import { EsMismoNumero } from "../../funciones/FuncionesNumero.js";
import styles from "../CuadroListaEditable.module.css";
import styleCols from "./TablaPersonaArbitro.module.css";

const TablaPersonaArbitro = (props) => {
  const lista = props.lista;
  const idElegido = props.idElegido;
  const onEditarItem = props.onEditarItem;
  const onEliminarItem = props.onEliminarItem;

  return (
    <React.Fragment>
      <tr key="0" className={`${styles.titulos}`}>
        <td className={`${styleCols.pais}`}>País</td>
        <td className={`${styleCols.debut}`}>Debut</td>
        <td className={`${styleCols.internacional}`}>Internacional</td>
        <td className={`${styleCols.retiro}`}>Retiro</td>
      </tr>
      {lista.map((item) => (
        <tr
          className={`${styles.valores} ${EsMismoNumero(idElegido, item.id) && styles.elegido}`}
          key={item.id}
        >
          <td className={`${styleCols.pais}`}>{item.paisArbitro && item.paisArbitro.nombre}</td>
          <td className={`${styleCols.debut}`}>{item.anioDebut}</td>
          <td className={`${styleCols.internacional}`}>{TextoAnioConBoolean(item.anioInternacional, item.internacional, false)}</td>
          <td className={`${styleCols.retiro}`}>{TextoAnioConBoolean(item.anioRetiro, item.retirado, false)}</td>
          <ColumnaBotoneraItem idItem={item.id} onEditarItem={onEditarItem} onEliminarItem={onEliminarItem} />
        </tr>
      ))}
    </React.Fragment>
  );
};

export default TablaPersonaArbitro;
