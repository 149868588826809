import CamposPersonaArbitro from "../campos/CamposPersonaArbitro.js";
import TablaPersonaArbitro from "../tablas/TablaPersonaArbitro.js";
import * as Campos from "../../constantes/Campos.js";
import * as FuncionesFecha from "../../funciones/FuncionesFecha.js";

// Funciones para CuadroEditableLista

export const armarFilasTabla = (lista, idElegido, onEditarItem, onEliminarItem) => {
  return (
    <TablaPersonaArbitro
      lista={lista}
      idElegido={idElegido}
      onEditarItem={onEditarItem}
      onEliminarItem={onEliminarItem}
    />
  );
}

export const OrdenarLista = (itemA, itemB) => {
  const ordenA = itemA.anioDesde * 1000 + itemA.anioHasta;
  const ordenB = itemB.anioDesde * 1000 + itemB.anioHasta;
  return ordenA - ordenB;
}

// Funciones para EditarItemLista

export const CamposEdicion = (idPadre, item, editar, actualizar, onCampoChange) => {
  return (
    <CamposPersonaArbitro
      idPadre={idPadre}
      item={item}
      editar={editar}
      actualizar={actualizar}
      onCampoChange={onCampoChange}
    />
  );
}

export const OnCampoChange = (item, campo, valor) => {
  switch (campo) {
    case Campos.Arbitro.PAIS:
      return { ...item, pais: valor };
    case Campos.Arbitro.ANIO_DEBUT:
      return { ...item, anioDebut: valor };
    case Campos.Arbitro.ANIO_INTERNACIONAL:
      return { ...item, anioInternacional: valor };
    case Campos.Arbitro.INTERNACIONAL:
      return { ...item, internacional: valor };
    case Campos.Arbitro.ANIO_RETIRO:
      return { ...item, anioRetiro: valor };
    case Campos.Arbitro.RETIRADO:
      return { ...item, retirado: valor };
    case Campos.Arbitro.OBSERVACIONES:
      return { ...item, observaciones: valor };
    case Campos.Arbitro.DUDAS:
      return { ...item, dudas: valor };
    default:
      throw new Error("Campo "+campo+ " no definido");
  }
};

export const ValidarItemGrabar = (item) => {
  let error = "";
  if (!item) {
    error = "No se informaron datos para el paso de la persona por el club";
  } else if (!item.pais) {
    error = "Debe seleccionar el país";
  } else if (item.anioDebut < FuncionesFecha.ANIO_MINIMO || item.anioDebut > FuncionesFecha.ANIO_MAXIMO) {
    error = "El año de debut es inválido";
  } else if (item.anioInteracional < FuncionesFecha.ANIO_MINIMO || item.anioInteracional > FuncionesFecha.ANIO_MAXIMO) {
    error = "El año de debut como internacional es inválido";
  } else if (item.anioRetiro < FuncionesFecha.ANIO_MINIMO || item.anioRetiro > FuncionesFecha.ANIO_MAXIMO) {
    error = "El año de retiro es inválido";
  } else if (item.anioDebut) {
    if (item.anioInternacional && item.anioInternacional < item.anioDebut) {
      error = "El año de debut internacional no puede ser previo al año de debut";
    } else if (item.anioRetiro && item.anioRetiro < item.anioDebut) {
      error = "El año de retiro no puede ser previo al año de debut";
    }
  } else if (item.anioInternacional && item.anioRetiro && item.anioRetiro < item.anioInternacional) {
      error = "El año de retiro no puede ser previo al año de debut internacional";
  }
  return error;
}
