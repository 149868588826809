import React, { useEffect, useState } from "react";
import ItemLista from "../../UI/ItemLista.js";
import BotonInsertar from "../../UI/botones/BotonInsertar.js";
import MensajeError from "../../UI/MensajeError.js";
import { encontrarCodigoString } from "../../funciones/FuncionesArray.js";
import { codigoParametro } from "../../funciones/FuncionesParametros.js";
import useGetListaURL from "../../../hooks/useGetListaURL.js";
import styles from "./Parametros.module.css";

export const Nivel = {
  GRUPO: "grupo",
  TIPO: "tipo",
  PARAMETRO: "parametro",
};

const ListaParametros = (props) => {
  const tipoParametro = props.tipo;
  const nivel = props.nivel;
  const itemActual = props.itemActual;
  const recargar = props.recargar;
  const [items, setItems] = useState([]);
  const { isCargando, erroresGetLista, fetchData, resetErroresGetLista } = useGetListaURL();
  const codigoActual = codigoParametro(props.itemActual);

  // efecto para limpiar el error de carga
  useEffect(() => {
    resetErroresGetLista();
  }, [resetErroresGetLista]);

  // efecto para cargar la lista de parámetros
  useEffect(() => {
    if (tipoParametro && tipoParametro.path !== "") {
      fetchData(tipoParametro.path, setItems);
    } else {
      setItems([]);
    }
  }, [tipoParametro, fetchData, recargar]);

  //efecto para actualizar la descripción del item seleccionado si fue modificado
  useEffect(() => {
    if (itemActual !== null) {
      setItems((prevItems) => prevItems.map((item) => codigoParametro(item) === codigoActual ? {...item, descripcion: itemActual.descripcion} : item
    ))};
  }, [itemActual, codigoActual]);

  const itemElegidoHandler = (codigoElegido) => {
    let itemElegido = encontrarCodigoString(items, codigoElegido);
    if (itemElegido !== null) {
      props.onItemElegido(nivel, itemElegido);
    } else {
      alert("No se encontró el item en la lista");
    }
  };

  const insertarItemHandler = () => {
    props.onInsertarParametro();
  };

  return (
    <div className={`${styles.columna}`}>
      {items && !isCargando && (
        <React.Fragment>
          <div key="titulo" className={`${styles.tituloLista}`}>
            {tipoParametro && (
              <React.Fragment>
                Parámetros
                <BotonInsertar
                  estilo={`${styles.botonInsertar}`}
                  onClick={insertarItemHandler}
                />
              </React.Fragment>
            )}
          </div>
          <div key="lista" className={`${styles.lista}`}>
            {items.map((item) => (
              <React.Fragment key={item.codigo}>
                {tipoParametro && (
                    <ItemLista
                      key={item.codigo}
                      id={item.codigo}
                      nombre={item.descripcion}
                      selected={codigoActual === undefined || item.codigo.toString() === codigoActual.toString()}
                      onItemElegido={itemElegidoHandler}
                      estilo={`${styles.itemListaParametros}`}
                    />
                  )}
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      )}
      <MensajeError errores={erroresGetLista} />
    </div>
  );
};

export default ListaParametros;
