import * as Campos from "../constantes/Campos.js";
import * as Ffecha from "../funciones/FuncionesFecha.js";
import * as Ftexto from "../funciones/FuncionesTexto.js";

export const Cuadro = {
  NOMBRES: "nombres",
  GENERALES: "generales",
  NACIMIENTO: "nacimiento",
  FALLECIMIENTO: "fallecimiento",
  JUGADOR: "jugador",
  TECNICO: "tecnico",
  ARBITRO: "arbitro",
};

export const CompletoOSimple = (completo, simple) => {
  if (completo && completo !== null) {
    return completo;
  } else {
    return simple;
  }
};

export const encontrarPersonaFiltros = (personas, filtros) => {
  let encontrado = null;
  if (personas && filtros.apellido) {
    personas.forEach((item) => {
      const apellidoNombre = Ftexto.TextoUpperNull(filtros.apellido) + ", " +  Ftexto.TextoUpperNull(filtros.nombre);
      if (Ftexto.TextoUpperNull(item.apellidoNombre) === apellidoNombre || 
          Ftexto.TextoUpperNull(item.apellidoNombreCompletos) === apellidoNombre) 
      {
        // TODO: falta sexo en los items
        if ((!item.paisNacimiento && !filtros.pais) || 
            (item.paisNacimiento && filtros.pais && Number(item.paisNacimiento.id) === Number(filtros.pais.id))) 
        {
          const fechaItem = Ffecha.CamposToFechaCorta(item.anioNacimiento, item.mesNacimiento, item.diaNacimiento);
          const fechaFiltros = Ffecha.CamposToFechaCorta(filtros.anioNacimiento, filtros.mesNacimiento, filtros.diaNacimiento);
          encontrado = fechaItem === fechaFiltros;
        }
      }
    });
  }
  return encontrado;
}

export const HayCamposCargadosArbitro = (arbitro) => {
  return (
    arbitro && arbitro.pais
  );
}

export const HayCamposCargadosJugador = (jugador) => {
  return (
    jugador &&
    (jugador.tipoPuesto || 
      jugador.observaciones || 
      jugador.dudas)
  );
}

export const HayCamposCargadosPersona = (persona, cuadro) => {
  switch (cuadro) {
    case Cuadro.GENERALES:
      return (
        persona &&
        (persona.tipoDocumento || 
          persona.nroDocumento || 
          persona.observaciones)
      );
    case Cuadro.NACIMIENTO:
      return (
        persona &&
        (persona.anioNacimiento ||
          persona.paisNacimiento ||
          persona.dudasFechaNacimiento ||
          persona.dudasLugarNacimiento)
      );
    case Cuadro.FALLECIMIENTO:
      return (
        persona &&
        (persona.anioFallecimiento ||
          persona.paisFallecimiento ||
          persona.detalleFallecimiento ||
          persona.dudasFallecimiento)
      );
    default:
      return persona !== null;
  }
};

export const HayCamposCargadosTecnico = (tecnico) => {
  return (
    tecnico &&
    (tecnico.anioDebut || 
      tecnico.observaciones)
  );
}

export const NombreApellido = (persona) => {
  if (persona) {
    if (persona.sobrenombre) {
      return persona.sobrenombre;
    } else {
      return persona.nombre + " " + persona.apellido;
    }
  } else {
    return "";
  }
};

export const NombreCompleto = (persona) => {
  if (persona) {
    if (persona.sobrenombre) {
      return (
        persona.sobrenombre +
        " (" +
        CompletoOSimple(persona.nombreCompleto, persona.nombre) +
        " " +
        CompletoOSimple(persona.apellidoCompleto, persona.apellido) +
        ")"
      );
    } else {
      return (
        CompletoOSimple(persona.apellidoCompleto, persona.apellido) +
        ", " +
        CompletoOSimple(persona.nombreCompleto, persona.nombre)
      );
    }
  } else {
    return "";
  }
};

export const TextoDocumento = (persona) => {
  let texto = "";
  if (persona) {
    if (persona.tipoDocumento) {
      texto = persona.tipoDocumento.sigla;
    }
    if (persona.numeroDocumento) {
      if (texto !== "") {
        texto += " ";
      }
      texto += persona.numeroDocumento;
    }
  }
  return texto;
};

export const OnCampoArbitroChange = (arbitro, campo, valor) => {
  switch (campo) {
    case Campos.Arbitro.PAIS:
      return { ...arbitro, pais: valor };
    case Campos.Arbitro.ANIO_DEBUT:
      return { ...arbitro, anioDebut: valor };
    case Campos.Arbitro.INTERNACIONAL:
      return { ...arbitro, internacional: valor };
    case Campos.Arbitro.ANIO_INTERNACIONAL:
      return { ...arbitro, anioInternacional: valor };
    case Campos.Arbitro.ANIO_RETIRO:
      return { ...arbitro, anioDebut: valor };
    case Campos.Arbitro.RETIRADO:
      return { ...arbitro, retirado: valor };
    case Campos.Arbitro.OBSERVACIONES:
      return { ...arbitro, observaciones: valor };
    case Campos.Arbitro.DUDAS:
      return { ...arbitro, dudas: valor };
    default:
      throw new Error("Campo " + campo + " no definido");
  }
};

export const OnCampoJugadorChange = (jugador, campo, valor) => {
  switch (campo) {
    case Campos.Jugador.TIPO_PUESTO:
      return { ...jugador, tipoPuesto: valor };
    case Campos.Jugador.OBSERVACIONES:
      return { ...jugador, observaciones: valor };
    case Campos.Jugador.DUDAS:
      return { ...jugador, dudas: valor };
    default:
      throw new Error("Campo " + campo + " no definido");
  }
};

export const OnCampoPersonaChange = (persona, campo, valor) => {
  let personaModif = null;
  switch (campo) {
    // nombres
    case Campos.Persona.APELLIDO:
      return { ...persona, apellido: valor };
    case Campos.Persona.APELLIDO_COMPLETO:
      return { ...persona, apellidoCompleto: valor };
    case Campos.Persona.NOMBRE:
      return { ...persona, nombre: valor };
    case Campos.Persona.NOMBRE_COMPLETO:
      return { ...persona, nombreCompleto: valor };
    case Campos.Persona.SOBRENOMBRE:
      return { ...persona, sobrenombre: valor };
    case Campos.Persona.DUDAS_APE_NOM:
      return { ...persona, dudasApellidoNombre: valor };
    // generales
    case Campos.Persona.TIPO_DOCUMENTO:
      return { ...persona, tipoDocumento: valor };
    case Campos.Persona.NRO_DOCUMENTO:
      return { ...persona, numeroDocumento: valor };
    case Campos.Persona.CODIGO_SEXO:
      return { ...persona, codigoSexo: valor };
    case Campos.Persona.APODO:
      return { ...persona, apodo: valor };
    case Campos.Persona.OBSERVACIONES:
      return { ...persona, observaciones: valor };
    // nacimiento
    case Campos.Persona.FECHA_NACIMIENTO:
      return {
        ...persona,
        anioNacimiento: valor[Ffecha.ANIO],
        mesNacimiento: valor[Ffecha.MES],
        diaNacimiento: valor[Ffecha.DIA],
      };
    case Campos.Persona.DUDAS_FECHA_NACIMIENTO:
      return { ...persona, dudasFechaNacimiento: valor };
    case Campos.Persona.LOCALIDAD_NACIMIENTO:
      return { ...persona, localidadNacimiento: valor };
    case Campos.Persona.PROVINCIA_NACIMIENTO:
      personaModif = { ...persona, provinciaNacimiento: valor };
      if (valor === null) {
        personaModif = { ...personaModif, localidadNacimiento: null };
      }
      return personaModif;
    case Campos.Persona.PAIS_NACIMIENTO:
      personaModif = { ...persona, paisNacimiento: valor };
      if (valor === null) {
        personaModif = {
          ...personaModif,
          provinciaNacimiento: null,
          localidadNacimiento: null,
        };
      }
      return personaModif;
    case Campos.Persona.DUDAS_LUGAR_NACIMIENTO:
      return { ...persona, dudasLugarNacimiento: valor };
    // fallecimiento
    case Campos.Persona.FECHA_FALLECIMIENTO:
      //TODO: reemplazar por los tres campos
      return { ...persona, fechaFallecimiento: valor };
    case Campos.Persona.LOCALIDAD_FALLECIMIENTO:
      return { ...persona, localidadFallecimiento: valor };
    case Campos.Persona.PROVINCIA_FALLECIMIENTO:
      personaModif = { ...persona, provinciaFallecimiento: valor };
      if (valor === null) {
        personaModif = { ...personaModif, localidadFallecimiento: null };
      }
      return personaModif;
    case Campos.Persona.PAIS_FALLECIMIENTO:
      personaModif = { ...persona, paisFallecimiento: valor };
      if (valor === null) {
        personaModif = {
          ...personaModif,
          provinciaFallecimiento: null,
          localidadFallecimiento: null,
        };
      }
      return personaModif;
    case Campos.Persona.DETALLE_FALLECIMIENTO:
      return { ...persona, detalleFallecimiento: valor };
    case Campos.Persona.DUDAS_FALLECIMIENTO:
      return { ...persona, dudasFallecimiento: valor };
    default:
      throw new Error("Campo " + campo + " no definido");
  }
};

export const OnCampoTecnicoChange = (tecnico, campo, valor) => {
  switch (campo) {
    case Campos.Tecnico.ANIO_DEBUT:
      return { ...tecnico, anioDebut: valor };
    case Campos.Tecnico.OBSERVACIONES:
      return { ...tecnico, observaciones: valor };
    default:
      throw new Error("Campo " + campo + " no definido");
  }
};
